<template>
  <h3 class="bh-text bh-text-left bh-text-bold">Missions</h3>
  <div class="row m-0 text-center">
    <div class="col-4">String ID</div>
    <div class="col-1">Tiers</div>
    <div class="col-2">Status</div>
    <div class="col-2">Created date</div>
    <div class="col-2">Creator</div>
    <div class="col-1"></div>
  </div>
  <div
    class="row bh-item text-center"
    v-for="mission in missions"
    :key="mission._id"
  >
    <div class="col-4">
      <p class="bh-text-background-secondary">
        <strong>{{ mission.idString }}</strong>
      </p>
    </div>
    <div class="col-1">
      <p class="bh-text-background">{{ mission.objectives.length }}</p>
    </div>
    <div class="col-2">
      <p :class="mission.status + ' bh-text-background'">{{ mission.status }}</p>
    </div>
    <div class="col-2">
      <p class="bh-text-background">{{ FilterDate(mission.creationDate) }}</p>
    </div>
    <div class="col-2">
      <!--<img
        :src="mission.creatorPicture"
        style="width: 50px; border-radius: 100%"
      />&nbsp;-->
      <p class="bh-text-background-secondary text-center"><strong>{{ mission.creator }}</strong></p>   
    </div>
    <div class="col-1">
      <router-link :to="'/missions/create/' + mission._id">
        <img src="../../assets/img/icons/IconTooltip.png" style="width: 40px" />
      </router-link>
    </div>
  </div>
</template>
<script>
import  MissionsService from "@/services/MissionsService.js";
import moment from "moment";

export default {
  data() {
    return {
      missions: [],
      isLoadingPage: true,
    };
  },
  mounted() {
    this.GetMissions();
  },
  methods: {
    GetMissions() {
      return MissionsService.GetMissions()
        .then(async ({ data }) => {
          this.missions = data.response;
          this.isLoadingPage = false;
        })
        .catch((e) => {
          console.log(e.response);
          //window.location.href = "/error";
        });
    },
    FilterDate(date) {
      if (date) return moment(String(date)).format("MM/DD/YYYY");
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/SurveyIndex.css";
</style>